<template>
  <router-view></router-view>
  <!--  <div>
      <div v-if="!keyPressed">

      </div>
      <div v-else>
        <div class="container">
          <div class="pt-5">
            <h1>Avast !</h1>
            <p>By pressing any keys from you pc will hide the question from you,
              but the timer will do his job as it is. <br>
            </p>
            <br>
            <button class="btn btn-primary btn-lg" @click="keyPressed = false">Resume Exam</button>
          </div>
        </div>

      </div>

    </div>-->

</template>

<script>
export default {
  name: "Layout"
}
</script>

<style scoped>

</style>